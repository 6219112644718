export default {
  "general": {
    "success": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd"])},
      "lessonPackage": {
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updaten geslaagd"])}
      }
    },
    "error": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutmelding"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrepen"])},
      "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rij"])},
      "col": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie"])},
      "lessonPackage": {
        "noName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van lespakket mag niet leeg zijn!"])},
        "playable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle levels moeten minsten op code of PSD gespeeld kunnen worden"])},
        "inUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lespakketten die in gebruik zijn mogen niet worden verwijderd!"])},
        "updateInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lespakketten die ingebruik zijn mogen niet worden geüpdate!"])},
        "tooLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam mag maximaal 50 characters lang zijn"])}
      }
    },
    "navbar": {
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docent"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
      "lessonPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak lespakketten"])},
      "levelMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level maker"])},
      "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
      "assignBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik lespakketten"])},
      "achievements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestaties"])},
      "wiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
      "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
    }
  },
  "home": {
    "learningTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelenderwijs leren"])},
    "learningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online laat studenten spelenderwijs kennis maken met de concepten van programmeren. Door middel van een simpel klik-en-sleepconcept leren zij de meest voorkomende basisprincipes van programmeren, zoals condities en herhaling"])},
    "challengeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra uitdaging"])},
    "challengeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet elke student is hetzelfde. Daarom biedt CodeQuest Online extra uitdaging voor gevorderde studenten. Door gebruik te maken van scripttaal of het vinden van betere oplossingen vinden zij alle uitdaging die zij nodig hebben"])},
    "interactiveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactief"])},
    "interactiveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is een interactieve leeromgeving waarin de gevolgen van acties in een leuke, visueel aantrekkelijke wereld wordt weergegeven"])},
    "makersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor studenten, door studenten"])},
    "makersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is gemaakt door een enthousiaste groep studenten van Universiteit Utrecht. Leren programmeren door anderen te leren programmeren, beter wordt het niet toch?"])}
  },
  "classes": {
    "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
    "allClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle klassen"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenten"])},
    "allStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle studenten"])},
    "bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lespakketen"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "allLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle levels"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer klas"])}
  },
  "lessonPackages": {
    "showPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbare lespakketen"])},
    "publicBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbare lespakketen"])},
    "myBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn lespakketten"])},
    "bundleLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekozen levels"])},
    "updateBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update lespakket"])},
    "allLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle levels"])},
    "removePublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publieke lespakketten kunnen niet worden verwijderd"])},
    "newPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw lespakket"])},
    "needName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam lespakket mag niet leeg zijn!"])},
    "newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van lespakket"])},
    "currBundleNewName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van dit lespakket"])},
    "publishBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceer lespakket"])},
    "publishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je dit lespakket publiceren? Deze actie kan niet ongedaan gemaakt worden."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceer"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oké"])},
    "psd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSD"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "tooltips": {
      "removeBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder dit lespakket"])},
      "publicationBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvraag versturen voor globaal gebruik"])},
      "publishBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceer lespakket voor globaal gebruik"])},
      "alreadyPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lespakket is al publiek!"])},
      "cloneBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceer dit lespakket"])},
      "psd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle levels kunnen met PSD taal gespeeld worden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle levels kunnen met geschreven code gespeeld worden"])},
      "lockBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit lespakket moet worden gespeeld op volgorde"])}
    }
  },
  "teacherLevels": {
    "ownLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen levels"])},
    "deleteLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete level"])},
    "deleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this level?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "tooltips": {
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceer dit level"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceer dit level zodat het beschikbaar is voor andere docenten"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder dit level"])}
    }
  },
  "levelMaker": {
    "newLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw level"])},
    "levelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam level"])},
    "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer type"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats blok"])},
    "makeLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak nieuw level"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokken"])},
    "errors": {
      "nameNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level naam nodig"])},
      "answerNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwoord nodig"])},
      "startNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start blok nodig"])},
      "stopNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde blok nodig"])},
      "muchStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teveel start blokken"])},
      "muchStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teveel einde blokken"])},
      "pathNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pad is niet compleet"])}
    },
    "selectableBlocks": {
      "square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierkant"])},
      "circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rondje"])},
      "heart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hart"])},
      "diamond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamand"])},
      "triangle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driehoek"])},
      "hexagon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeshoek"])}
    },
    "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
    "themes": {
      "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boerderij"])},
      "desert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woestijn"])}
    },
    "seedGen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer een nieuwe seed"])},
    "goldNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal goud behalen"])},
    "silverNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal zilver behalen"])},
    "bronzeNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal brons behalen"])}
  },
  "levels": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer klas en lespakket"])}
  },
  "game": {
    "achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaald"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, niet gehaald"])},
    "levelOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveloverzicht"])},
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructies"])},
    "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabelen"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "savedSucceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgeslagen"])},
    "savedFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mislukt"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
    "tooltips": {
      "breakpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats punten waar het programma moet stoppen"])},
      "removeBreakpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haal dit pauzepunt weg"])},
      "psdSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander tussen PSD en code"])},
      "switchAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander tussen level antwoord and level maker"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef het antwoord van het level weer"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongedaan maken"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw toepassen"])},
      "stepOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga een stap verder"])},
      "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop het uitvoeren van het level"])},
      "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer het level uit"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla het level op"])},
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk een voorbeeld van het level"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload het level zodat het gebruikt kan worden in lespakketten"])},
      "addVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg de variabele toe"])},
      "addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg de optie toe"])},
      "removeOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder de optie"])},
      "switchEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga terug naar level maker"])}
    }
  },
  "achievements": {
    "medalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn medailles"])},
    "achievementHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn prestaties"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe prestatie(s) behaald!"])},
    "noImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen afbeelding"])},
    "name": {
      "golds10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 Gouden medailles"])},
      "golds50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 Gouden medailles"])},
      "golds100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 Gouden medailles"])},
      "superMedal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super medaille"])},
      "ifStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If-statement"])},
      "whileLoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While-loop"])},
      "moved100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 Keer verplaatst"])},
      "moved500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500 Keer verplaatst"])},
      "moved1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000 Keer verplaatst"])},
      "dead10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 Keer doodgegaan"])},
      "dead50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 Keer doodgegaan"])},
      "dead100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 Keer doodgegaan"])}
    },
    "description": {
      "golds10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaal 10 of meer gouden medailles."])},
      "golds50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaal 50 of meer gouden medailles."])},
      "golds100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaal 100 of meer gouden medailles."])},
      "superMedal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaal een super medailles."])},
      "ifStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haal een level door gebruik te maken van een if-statement."])},
      "whileLoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haal een level door gebruik te maken van een while-loop."])},
      "moved100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zet 100 of meer stappen."])},
      "moved500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zet 500 of meer stappen."])},
      "moved1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zet 1000 of meer stappen."])},
      "dead10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga 10 of meer keer dood."])},
      "dead50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga 50 of meer keer dood."])},
      "dead100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga 100 of meer keer dood."])}
    }
  },
  "statistics": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistieken"])},
    "totalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale statistieken"])},
    "avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde"])},
    "avarage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "total2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "timesOpened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aantal keer geopend"])},
    "timesOpenedCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal keer geopend"])},
    "timeSpend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tijd besteed"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aantal pogingen"])},
    "attemptsCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal pogingen"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["u"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "successfullRuns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal keer succesvol uitgevoerd"])},
    "memoryUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geheugengebruik"])},
    "memoryUsageCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheugengebruik"])},
    "solutionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zie oplossing student"])},
    "allWorkingSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels waar iedereen een werkende oplossing heeft"])},
    "peopleWorkingSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenten met een werkende oplossing"])},
    "levelsWorkingSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels met een werkende oplossing"])},
    "allWorkedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels waar iedereen aan gewerkt heeft"])},
    "peopleWorkedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenten die aan dit level gewerkt hebben"])},
    "levelsWorkedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels waaraan gewerkt is"])},
    "ideLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal gebruikt voor IDE"])},
    "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkend"])},
    "notWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet werkend"])},
    "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets foutgegaan: statistieken konden niet geladen worden."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oké"])},
    "tooltips": {
      "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De hoeveelheid geheugen die een student gebruikt heeft voor hun huidige oplossing ten opzichte van de bekende optimale oplossing. De medaille is hiervan afhankelijk. Super < 0%, Goud: = 0%, Zilver ≤ 15%, Brons ≤ 30%"])},
      "successRuns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het percentage uitvoeringen met een oplossing die het level kan halen. Een hoger percentage betekend dat minder uitvoeringen eindigde in een mislukking"])}
    },
    "world": {
      "stepsTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stappen gezet"])},
      "timesDied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal keer gestorven"])}
    }
  },
  "wiki": {
    "exampleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een wiki pagina"])},
    "progHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over programmeren"])},
    "codeQuestHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over CodeQuest Online"])},
    "psd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De PSD"])},
    "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokken in de PSD slepen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voortgang opslaan"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code testen"])},
    "break": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakpoints"])},
    "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst / strings"])},
    "bool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datatypes"])},
    "var": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabelen"])},
    "enum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties / Enums"])},
    "newvar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe variabelen toevoegen"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekenen en programmeren"])},
    "par": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haakjes"])},
    "bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binaire operators"])},
    "assi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardes toewijzen ( := )"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardes checken"])},
    "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input switchen"])},
    "condi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaardes en condities"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool operators: de En"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool operators: de Of"])},
    "exor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool operators: de Exor"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool operators: de Niet"])},
    "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Als-functie"])},
    "while": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Zolang-functie"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code schrijven"])},
    "pow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machtsverheffen ( ^ )"])},
    "mod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo rekenen ( % )"])},
    "inf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oneindig ( ∞ )"])},
    "codequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over CodeQuest Online"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De profielpagina"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal selecteren"])},
    "progway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manier van programmeren"])},
    "levelsel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels selecteren"])},
    "levclose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels selecteren: gesloten levels"])},
    "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medailles halen"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voortgang en statistieken"])},
    "achev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestaties"])},
    "botzQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over BotzQuest"])},
    "quest2D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over het ontwikkelteam: Quest 2D"])},
    "classWiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor docenten: klassen beheren"])},
    "classign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor docenten: lespakketen toewijzen"])},
    "packWiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor docenten: lespakketten maken"])},
    "levMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor docenten: de Level Maker"])},
    "psdDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De PSD (Programma Structuur Diagram) is een schema waarin je programmacode kan zetten. De code bestaat uit blokjes en die blokjes kan je zelf in de PSD slepen om code te maken die zorgt dat je poppetje gaat bewegen."])},
    "psdDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met CodeQuest Online ga je leren hoe je een programma maakt met zo'n PSD."])},
    "dragDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de PSD kun je instructies slepen en zelf kiezen op welke plek je die wil neerzetten. Je doet dit door met je muis op een instructie te staan en de linker muisknop ingedrukt te houden. Daarna beweeg je met je muis dat blokje naar de juiste plek in de PSD."])},
    "saveDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boven de PSD heb je een groene knop met een soort SD kaartje erop. Hier kun je op klikken als je de PSD zoals je die nu hebt wil opslaan. Dit is handig voor als je later een keer verder wil gaan met programmeren maar al wel aardig ver bent met het level."])},
    "testDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer je denkt dat je een oplossing hebt geprogrammeerd, kun je je code testen. Druk hiervoor op het groene pijltje boven de PSD. Je zal zien dat het poppetje gaat bewegen zoals je de code ervoor hebt geschreven. Met de oranje pijlen kun je een stap vooruit of achteruit doen in de code."])},
    "breakDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakpoints zijn punten in de code waar je wil dat het programma pauzeert tijdens het uitvoeren. Je kan deze punten zelf neerzetten door op de rode pauzeknop te drukken en te klikken op een blokje in je PSD waar je wil dat er een breakpoint komt. Dit is een goed middel als je een fout in de code hebt gemaakt maar je weet niet wat er fout gaat. Als het programma daar pauzeert, kun je rustig kijken wat de waardes zijn en zo beter kijken hoe je je code verbetert."])},
    "breakDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierboven zie je dat we bij twee instructies een breakpoint hebben neergezet, want die kan je herkennen aan de rode vierkantjes. Om de breakpoints weg te halen klik je nog een keer op de pauzeknop en dan op de breakpoint. Breakpoints kun je ook gebruiken bij geschreven code."])},
    "stringDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een tekst is een rij letters en andere leestekens. Programmeurs gebruiken hier de Engelse term 'string' voor. Het begin en einde van de string worden aangegeven met aanhalingstekens, bijvoorbeeld 'robot'."])},
    "boolDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een booleaan (boolean of bool in het Engels) is een waarde met maar 2 mogelijkheden: de waarde is 'waar' (true) of 'niet waar' (false). Voor computers is dit een makkelijke waarde om af te lezen en om mee te werken. Programmeurs gebruiken een kortere versie om het verschil tussen waar en niet waar aan te geven. Een waarde van '1' is 'waar' en een waarde van '0' is 'niet waar'. "])},
    "dataDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De data die een computer bewaart, kan verschillende vormen hebben. Denk aan een getal, dat is iets waar we mee kunnen rekenen. Maar je hebt ook andere datatypes zoals je hierboven al kennis hebt gemaakt met de string en de bool. Belangrijk tijdens het programmeren is dat je deze types niet zomaar door elkaar gebruikt. Je kan bijvoorbeeld niet de optelsom ' 'computer' + 6' doen, want je kan een string niet optellen bij een getal."])},
    "varDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een variabele is een stukje in je programma dat een bepaalde waarde kan hebben. Meestal zal deze waarde veranderen wanneer het progamma aan het werk is. De waarde is dan variabel, dit is ook waar de naam vandaan komt. Variabelen hebben zelf ook altijd een naam zodat je makkelijk kan herkennen waar de variabele voor gebruikt wordt. Bijvoorbeeld, een variabele genaamd 'leeftijdVanRobbie' houdt de leeftijd bij van een karakter dat Robbie heet."])},
    "varDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je alleen een variabele hebt, is dat niet zo spannend. Als programmeur wil je dat variabelen de hele tijd veranderen, want dan gebeurt er ook iets. Je kan met variabelen rekenen, je kan de waarde controleren en nog veel meer."])},
    "enumDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een enum is een speciale variabele. Bij deze variabele heb je altijd een beperkt aantal mogelijke waardes, die je zelf van tevoren aangeeft. Een goed voorbeeld van een enum is de dag van week: de variabele heeft een naam als dagVanDeWeek en de mogelijke waardes kunnen alleen maandag, dinsdag, woensdag, donderdag, vrijdag, zaterdag of zondag zijn. Hieronder zie je nog een goed voorbeeld van een enum die al standaard in CodeQuest zit, namelijk de move, oftewel bewegen. Je hebt hiermee de mogelijkheid om omhoog, omlaag, naar links of naar rechts te bewegen."])},
    "newvarDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnen een level van CodeQuest Online kun je ook zelf variabelen toevoegen. Kies hiervoor eerst wat voor datatype je variabele moet hebben. Moet de variabele een getal, een tekst, een bool of een enum zijn? Vervolgens kies je een toepasselijke naam en je drukt op de groene knop om je variabele knop aan te maken. Je variabele zal meteen toegevoegd worden aan je instructies en je kan deze ook meteen in de PSD gebruiken."])},
    "calcDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekenen bij het programmeren werkt net zoals gewoon rekenen. Je hebt twee getallen en je telt ze bij elkaar op ( + ) , trekt ze van elkaar af ( - ), je kan ermee vermenigvuldigen ( * ) en ze door elkaar delen ( / ). Deze rekensommen kun je alleen doen met getallen of variabelen waar een getal in zit."])},
    "parDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschijnlijk heb je wel bij rekenen geleerd dat er een bepaalde volgorde zit in een rekensom. Bijvoorbeeld dat je altijd moet beginnen met wat tussen haakjes staat. Deze regel geldt ook tijdens het programmeren. Als je wil dat iets correct van tevoren worden uitgerekend, zet je er haakjes omheen."])},
    "binDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een binaire operator is een instructie die iets doet met 2 waardes. Denk aan de plus ( + ), die heeft twee getallen en telt ze bij elkaar op. Tijdens het programmeren ga je veel gebruik maken van binaire operators."])},
    "assiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toewijzen betekent 'een variabele een bepaalde waarde geven.' Hiervoor gebruik je de speciale := . Aan de linkerkant zet je een variabele waarvan je de waarde wil veranderen. Aan de rechterkant zet je een waarde of een andere variabele waarvan je de waarde wil doorgeven. Voorbeelden: ' leeftijdVanRobbie := 6 ' zorgt dat de waarde van leeftijdVanRobbie 6 wordt. ' leeftijdVanRobbie := leeftijdVanDanny ' zorgt dat leeftijdVanRobbie de waarde van leeftijdVanDanny overneemt."])},
    "assiDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het kan ook gebeuren dat je een toewijzing doet waarbij je meerdere waardes wil combineren. Dan moet je aan de rechterkant van de toewijzing binaire operators gebruiken. Voorbeeld: Robbie is 10 jaar ouder dan Danny. Als we de leeftijd van Robbie willen hebben, dan doen we dat als volgt: ' leeftijdVanRobbie := leeftijdVanDanny + 10 '. Je kan alle binaire operators gebruiken in een toewijzing, zolang je het goede datatype aanhoudt."])},
    "checkDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij het checken van waardes ga je variabelen of waardes met elkaar vergelijken. Er zijn hier meerdere opties voor. Zo heb je de '=', die controleert of twee waardes precies hetzelfde zijn, als dit klopt krijg je een 1 terug, anders krijg je een 0. Bijvoorbeeld, '6 = 6' is waar dus een 1 en '6 = 9' is niet waar dus een 0."])},
    "checkDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je kan ook kijken of een waarde groter of kleiner is dan een andere waarde met > en <, je controleert daarmee of waarde 1 groter of kleiner is dan waarde 2. Let erop dat hier altijd wordt gecontroleerd of het getal aan de open kant van het pijltje de grootste is en dat het getal bij de punt de kleinste van de twee is. Bijvoorbeeld, de vergelijking 9 > 6 is waar dus een 1, maar 9 < 6 is niet waar, een 0. 9 < 9 is ook een false, want een getal kan niet groter / kleiner zijn dan zichzelf"])},
    "checkDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daarnaast heb je de symbolen ≥ en ≤, die zijn eigenlijk een < of > en een = samen, dus die controleren of waarde 1 groter / kleiner is of hetzelfde is als waarde 2. Hier geldt dan dat 9 ≥ 6 en 9 ≥ 9 allebei waar zijn, maar 9 ≥ 10 is niet waar."])},
    "checkDes4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder kun je ook het tegenovergestelde doen van =, dus controleren of twee waardes niet hetzelfde zijn, met het teken ≠. In dit geval is de vergelijking '6 ≠ 9' wel waar dus krijg je hier een 1 terug."])},
    "checkDes5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit zijn allemaal operators die een waar teruggeven als het klopt en een niet waar als het niet klopt, je zal dus altijd een bool terugkrijgen. Bij de groter en kleiner dan operators kun je alleen getallen gebruiken. Bij de = en ≠ kun je ook strings met elkaar vergelijken."])},
    "switchDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer je een toewijzing hebt gedaan met bijvoorbeeld een getal, kun je kiezen of je zelf een waarde wil invullen of dat je een variabele of operator wil gebruiken. Dit kan je aanpassen door op het oranje switch knopje te klikken, waarmee je dus kan wisselen tussen die twee opties."])},
    "condiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij sommige taken moet je een voorwaarde meegeven. Een voorwaarde bepaalt of die taak dan wordt uitgevoerd of niet. De voorwaarde bestaat altijd uit een waarde check. Hieronder zie je bijvoorbeeld een voorwaarde waar het figuur waar je op staat een hartje moet zijn."])},
    "andDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De En is een binaire operator die twee waardes vergelijkt. Het resultaat van deze operator zal een bool waarde zijn. De waarde die je hieruit terugkrijgt is '1' als beide vergeleken bool waardes ook 'waar' zijn. Anders is het resultaat altijd '0'. Je kan alleen bool waardes gebruiken in deze operator."])},
    "orDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Of is een binaire operator die twee waardes vergelijkt. Het resultaat van deze operator zal een bool waarde zijn. De waarde die je hieruit terugkrijgt is '1' als een of beide van de vergeleken bool waardes ook 'waar' zijn. Als beide waardes 'niet waar' zijn is het resultaat ook '0'. Je kan alleen bool waardes gebruiken in deze operator."])},
    "exorDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Exor is een binaire operator die twee waardes vergelijkt. Het resultaat van deze operator zal een bool waarde zijn. De waarde die je hieruit terugkrijgt is '1' als de twee waardes verschillen (dus een is waar en een is niet waar). Als de vergeleken bool waardes dezelfde waarde hebben (allebei zijn waar of niet waar) dan is het resultaat '0'. Je kan alleen bool waardes gebruiken in deze operator."])},
    "notDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Niet is een unary operator, dat betekent dat je maar één waarde gebruikt als input. De Niet krijgt altijd een bool en draait deze om. Een 'waar' wordt dus een 'niet waar' en andersom. Je kan alleen bool waardes gebruiken. Als je code schrijft, kun je dit toepassen door een uitroepteken voor een variabele te zetten, bijvoorbeeld '!rood'"])},
    "ifDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij een Als operator heb je altijd een splitsing met twee mogelijkheden. Om te bepalen welk pad je op gaat check je de waarde van de voorwaarde. Als de waarde 'waar' is, voert het programma alleen de code uit die in de 'waar' kant staat. Is de waarde 'niet waar', dan zal hij de code aan de 'niet waar' kant uitvoeren. De voorwaarde moet een operator zijn die een bool als resultaat geeft. Als je code schrijft, gebruik je het Engelse woord 'if' voor de waar branch en 'else' voor de niet waar branch."])},
    "ifDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het voorbeeld hierboven checken we of we op een hartje staan. Als we op een hartje staan, zal hij de code in de true tak uitvoeren, oftewel naar links bewegen (gemarkeerd met geel). Wanneer we niet op een hartje staan gaat hij kijken naar de false tak (in lichtblauw). Dan zal hij dus omhoog bewegen."])},
    "whileDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Zolang operator werkt met gebruik van herhaling. De operator checkt een voorwaarde, en hij blijft de code eronder uitvoeren zolang de waarde van de voorwaarde 'waar' is. Wanneer dit niet zo is, zal hij stoppen met deze loop en zal het programma doorgaan met de rest van de PSD. De voorwaarde moet een operator zijn die een bool als resultaat geeft. Als je code schrijft, gebruik je het Engelse woord 'while'"])},
    "whileDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het voorbeeld hierboven checken we of we op een hartje staan. Zolang we op een hartje staan, zal hij de code in de zolang functie herhalen, oftewel naar links bewegen (gemarkeerd met geel). Wanneer we op een ander figuur terechtkomen, zal hij stoppen met de zolang en gaat hij door naar de code eronder (in lichtblauw). Dan zal hij dus omhoog bewegen."])},
    "writeDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naast het gebruik van een PSD kun je ook code schrijven. Dat lijkt misschien eerst ingewikkeld, maar het valt best mee. Je gebruikt eigenlijk dezelfde woorden en tekens als die je gaat tegenkomen in de PSD. Maar je zal ook een paar verschillen zien. We zullen hier de belangrijkste regels opsommen."])},
    "writeDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Een instructie eindigt altijd met een puntkomma, oftewel een ;. Hierdoor weet de computer dat hij naar de volgende instructie kan en dat de instructies van elkaar gescheiden zijn."])},
    "writeDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Voor grote instructies met meerdere toewijzingen gebruik je accolades, dat zijn deze sierlijke haakjes:. Dit gebruik je bijvoorbeeld voor de zolang en de als functies. Let op dat je bij de als functie eerst de haakjes plaatst voor de waar branch en daaronder de haakjes voor de niet waar branch."])},
    "writeDes4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Voorwaardes staan vaak in haakjes zoals ( vlak == rood ) achter de zolang en de als."])},
    "powDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machtsverheffen is een binaire operator met een speciale keersom. Het tweede getal geeft aan hoeveel keer je het eerste getal met zichzelf wil vermenigvuldigen. Voorbeeld: de machtsverheffing ' 2 ^ 4 ' betekent dat ik 4 keer een 2 vermenigvuldig, oftewel de keersom wordt ' 2 x 2 x 2 x 2 '. Je kan alleen machtsverheffen met getallen."])},
    "modDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo rekenen is een speciale manier van delen. Bij modulo rekenen gaat het erom hoeveel rest je overhoudt als je het getal eerlijk zou verdelen in even grote stukken. Stel je geeft een pizzafeestje en je wil mensen altijd 4 pizzapunten geven. Maar je hebt 14 pizzapunten, dat is niet deelbaar door 4. We houden er geen rekening mee hoeveel mensen er zijn op het feestje, een gast krijgt vier stukken of helemaal niks. Dan kun je aan 3 mensen 4 pizzapunten uitdelen (want 3 x 4 = 12) en je houdt 2 pizzapunten over. Het gaat er niet om hoe vaak je 4 pizzapunten kan uitdelen, alleen het restgetal willen we weten. Kortgezegd kun je deze rekensom opschrijven als: 14 % 4 = 2. Daardoor geldt ook 6 % 4 = 2, want 6 is een aantal keer 4 plus 2, net zoals 14. Je kunt alleen modulo rekenen met getallen."])},
    "modDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ander goed voorbeeld van modulo rekenen is met een klok. Als de tijd 21:15 is, dan zeg je 'Het is kwart over 9'. Maar er staat 21, dus wanneer je de tijd afleest, doe je eigenlijk 21 % 12 = 9. In de onderstaande video wordt dit in meer detail uitgelegd."])},
    "infDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oneindig is een speciaal teken dat precies doet wat zijn naam is: er zit geen einde aan. Je kan dit gebruiken om een programma door te laten gaan zonder dat het ooit moet stoppen."])},
    "codequestDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is een online programma waarmee je op interactieve manier leert over de concepten van programmeren. Je doel is om met meerdere instructies je karakter door een doolhof heen te sturen en zo te leren nadenken over het schrijven van computerprogramma's. CodeQuest Online is een product ontwikkeld door Team Quest2D en afkomstig van samenwerking tussen de Stichting Praktijkleren en de Universiteit Utrecht."])},
    "loginDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de inlogpagina van CodeQuest Online kun je inloggen om in jouw eigen account te komen, waar je alle levels kan oplossen die door je docent zijn uitgekozen. Om in te loggen gebruik je je unieke gebruiksnaam en wachtwoord die je hebt gekregen van de Stichting Praktijkleren. "])},
    "profileDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de profielpagina kun je je gegevens bekijken, je kan zien in welke klassen je zit en je kan hier je avatar aanpassen."])},
    "avatarDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars zijn profielfoto's die je voor je eigen account kan kiezen. Zo kan je ook herkennen dat je ingelogd bent, dan zie je namelijk rechtsboven je avatar staan. Je kan zelf kiezen voor een avatar dat je meer bij jezelf vind passen. Vergeet niet om op 'opslaan' te klikken als je iets hebt aangepast."])},
    "settingDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via deze pagina kun je de instellingen van CodeQuest aanpassen naar je eigen voorkeur. Zo kun je er zelf voor kiezen om de geluidseffecten aan of uit te zetten of dat je wel of niet zichtbaar wil zijn op het leaderboard. Aan de rechterkant kan je de taal instellen. Vergeet niet om op 'opslaan' te klikken als je iets hebt aangepast."])},
    "langDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de instellingenpagina kun je kiezen of je CodeQuest wil spelen in het Nederlands of in het Engels. Je kan hier kiezen of je de hele pagina in het Engels of Nederlands wil, of dat je alleen wil leren programmeren met Nederlands of Engelse termen."])},
    "progwayDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij sommige levels is er niet gekozen voor een bepaalde manier van oplossen, dat kan zowel via de PSD als via het schrijven van code. Met deze instelling kun je kiezen waar je meer mee wil oefenen, oftewel, wil je levels voor nu liever met de PSD of met code oplossen."])},
    "levelselDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op deze pagina kun je een level uitkiezen om te spelen. Eerst selecteer je een bundel die je docent voor je heeft uitgekozen. Daarna kun je zien welke levels er allemaal beschikbaar zijn in deze bundel. Door met de muis op een level te klikken, zal je in de levelomgeving terechtkomen."])},
    "levcloseDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer er een groot slot boven een level staat, moet je eerst de levels ervoor oplossen voordat je dit level kan spelen."])},
    "medalsDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de hoek van een level kun je zien welke medaille je hebt gehaald voor dat level. Je ziet hier altijd de hoogste medaille die je voor het level hebt gehaald. Hoe korter je PSD / code is, hoe beter je het level hebt gedaan! Verzamel jij alle gouden medailles?"])},
    "statsDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de prestatie pagina kun je je voortgang zien in CodeQuest Online. Hier kun je bijvoorbeeld vinden hoeveel van de beschikbare levels je hebt voltooid, hoeveel medailles je hebt gehaald en je kan aan de rechterkant unieke prestaties zien."])},
    "achevDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestaties laten zien dat je bepaalde mijlpalen hebt gehaald in de game. Denk bijvoorbeeld aan het verzamelen van minstens 50 gouden medailles. Lukt het jou om alle prestaties te verzamelen?"])},
    "botzQDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotzQuest is de voorganger van CodeQuest-Online, ontwikkeld door Rob Kommeren voor Stichting Praktijkleren. In BotzQuest moet je Robbie Robot helpen het doolhof uit te komen door hem instructies te geven. CodeQuest Online borduurt hierop verder met een frisse webomgeving en nieuwe thema's."])},
    "quest2DDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is gemaakt door een enthousiaste groep studenten van Universiteit Utrecht. Leren programmeren door anderen te leren programmeren, voor studenten en door studenten. beter wordt het niet toch?"])},
    "classWikiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In je docentenomgeving kun je de resultaten inzien van al je leerlingen per level. Selecteer links een klas die je wil inzien.Vanaf hier kan je kiezen voor het bekijken van de prestaties van de hele klas op een bepaald level wil zien of dat je juist kijkt naar de prestaties van een specifieke student. Kies een bundel waar de studenten aan hebben gewerkt en bekijk de prestaties per level of over de hele bundel heen."])},
    "classWikiDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard opent eerst de statistieken. Dit laat bijvoorbeeld zien hoe vaak een student (of alle studenten) levels hebben geopend en geprobeerd hebben én hoeveel tijd ze daaraan hebben besteed. Je hebt hier ook een knop om de daadwerkelijke oplossing van een leerling te bekijken. Je kan ook wisselen naar het andere tabblad en dan zie je zaken als percentages van het geheugengebruik en het aantal succesvolle pogingen."])},
    "classignDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je kan als docent je klassen bundels meegeven waar je studenten vervolgens mee kunnen werken. Klik links op de klas waar je de bundels wil veranderen. Nu zie je in het midden een lijst van alle bundels die al aan de klas zijn toegewezen, hier kan ook niks staan in het begin. Rechts zie je alle beschikbare bundels. Klik op een groen plusje om die bundel toe te voegen aan je klas. Klik op het rode minknopje om een bundel uit de klas te halen."])},
    "classignDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je klaar bent met het samenstellen van de bundels voor je klas, druk je op de groene knop 'werk klassen bij' om je aanpassingen op te slaan. Druk op de rode reset knop om alles terug te draaien naar het laatste moment dat je hebt opgeslagen."])},
    "packWikiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als docent kun je zelf lespakketten maken voor je studenten. Dit kan via de tab 'lespakket maken' onder het docenten menu. Als je een nieuw lespakket maakt, vul je eerst een naam in voor dat pakket. Dat kan bijvoorbeeld het onderwerp zijn van het pakket. Druk op 'Nieuw lespakket' om dat pakket aan te maken. Klik vervolgens op dat pakket om er levels in te kunnen slepen."])},
    "packWikiDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het levels slepen is erg eenvoudig. Rechts zie je alle levels, daar kan je op de plusknop klikken om het level toe te voegen. Als je een level wil weghalen, klik je op de rode minknop."])},
    "packWikiDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder is het belangrijk om aan te geven of studenten de levels moeten oplossen via een PSD of via geschreven code. Daarnaast kan je ook aangeven of de levels verplicht in volgorde moeten worden opgelost (dat betekent dat een level voor de student op slot zit totdat hij alle voorgaande levels heeft opgelost) of dat hij direct bij alle levels kan. De tweede is als standaard ingesteld."])},
    "levMakerDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In CodeQuest Online heb je als docent de mogelijkheid om zelf levels te creëren. Begin een nieuw level door links te klikken op het grote vlak met een plus erop. De level maker zal openen en die zal je eerst vragen om een naam te noemen. Geef de naam en druk op 'Maak nieuw level'."])},
    "levMakerDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu opent de level maker en hier heb je verschillende acties die je kan doen. Laten we beginnen met het plaatsen van blokken. In het midden heb je een vlak met alle beschikbare blokken. Selecteer hier een bepaald blok van en klik vervolgens een vakje in het rooster aan om dat vlakje daar te plaatsen. Dat vlak blijft geselecteerd totdat je een ander kleur blok aanklikt. Start en einde zijn de start en eindpunten van het level, bij deze blokken verschijnt ook een menuutje waarin je kan aangeven wat voor figuurtje er op deze vlakken moet worden getekend."])},
    "levMakerDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het rooster heb je nog een aantal andere opties die je rechtsboven ziet staan. Je kan in- en uitzoomen met de + en - knoppen. Dit kan overigens ook met het muiswiel. Je hebt ook het handje, als je die knop aanklikt kun je het rooster verslepen."])},
    "levMakerDes4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bovenin heb je ook een aantal opties. Gebruik de oranje pijltjes om een actie ongedaan te maken of weer terug te zetten. Druk op de groene opslaan knop om je werk op te slaan. Helemaal rechts kan je op uploaden drukken als je klaar bent met het maken van het level en als je het level ook daadwerkelijk speelbaar wil maken voor je studenten. Vergeet niet dat je het level dan nog wel aan een bundel moet toevoegen."])}
  },
  "profile": {
    "userInfoBlockHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens"])},
    "avatarBlockHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars"])},
    "avatarButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "classroomBlockHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
    "permission": {
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docent"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])}
    }
  },
  "settings": {
    "game": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
      "soundEffects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geluidseffecten"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muziek"])}
    },
    "language": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal website"])},
      "programming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal programmeren"])},
      "programmingStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manier van programmeren"])},
      "schematically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schematisch"])},
      "typed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typen"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
      "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])}
    },
    "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])}
  },
  "classroomPackages": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetten"])},
    "myClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn klassen"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werk klassen bij"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen succesvol bijgewerkt!"])},
    "availableBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbare lespakketten"])},
    "selectClassroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een klas"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oké"])}
  }
}