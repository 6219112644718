
import { Options, Vue } from "vue-class-component";
import Navbar from "@/components/Navbar.vue"; // @ is an alias to /src
import { authAPI, userAPI } from "@/utils/apiCalls/index";

@Options({
  data() {
    return {
      theme: "",
    };
  },
  watch: {
    //Change theme on store theme change
    "$store.state.theme"() {
      this.theme = this.$store.getters.getTheme;
    },
  },
  beforeMount() {
    //Set theme
    this.theme = this.$store.getters.getTheme;
  },
  mounted() {
    // Check if there's an active user session
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshExp = localStorage.getItem("refreshTokenExp");
    const authtoken = localStorage.getItem("authToken");
    const authExp = localStorage.getItem("authTokenExp");
    const userInfo = localStorage.getItem("userInfo");

    if (
      refreshToken &&
      refreshExp &&
      parseInt(refreshExp) > Date.now() / 1000
    ) {
      if (authtoken && authExp && parseInt(authExp) > Date.now() / 1000) {
        this.$store.commit("setAuthTokens", {
          authToken: authtoken,
          refreshToken: refreshToken,
        });
      } else {
        // Auth token has expired, get a new one
        authAPI.refreshTokens();
      }

      if (userInfo) {
        this.$store.commit("setUserInfo", JSON.parse(userInfo));
      } else {
        userAPI.setCurrentUserInfo();
      }
    }
    //Set website language to the preferred language
    if (userInfo) {
      this.$i18n.locale = JSON.parse(userInfo).preferences.siteLang;
    }
  },
  components: {
    Navbar,
  },
})
export default class App extends Vue {}
