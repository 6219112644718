
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    //Animate stripes when visible on screen
    const stripes = document.querySelectorAll(".sellText");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("animationVisible");
        }
      });
    });
    stripes.forEach((stripe) => {
      observer.observe(stripe);
    });
  },
})
export default class Home extends Vue {}
