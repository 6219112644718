export default {
  "general": {
    "success": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "lessonPackage": {
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated successfully"])}
      }
    },
    "error": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])},
      "col": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "lessonPackage": {
        "noName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of lesson package can't be empty!"])},
        "playable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels should atleast be playable by either PSD or code"])},
        "inUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson packages in use can't be deleted!"])},
        "updateInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson packages in use can't be updated!"])},
        "tooLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the lesson package can't be longer than 50 characters"])}
      }
    },
    "navbar": {
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
      "lessonPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make lesson packages"])},
      "levelMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level maker"])},
      "assignBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use lesson packages"])},
      "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
      "achievements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievements"])},
      "wiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
    }
  },
  "home": {
    "learningTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playful learning"])},
    "learningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online makes students familiar with the concepts of programming in a playful way. Using a simple drag and drop system they learn the most occuring basic programming principles, like conditions and loops"])},
    "challengeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra challenge"])},
    "challengeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not every student is the same. That's why CodeQuest Online has extra challenges for advanced students. By using scripts or finding better solutions they can get all the challenge they need"])},
    "interactiveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive"])},
    "interactiveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is an interactive learning environment where the consequences of actions are shown in a nice, visually attricative way"])},
    "makersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For students, by students"])},
    "makersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is made by an enthousiastic group of students from Utrecht University. Learning to program by teaching others to program, it doesn't get better than that right?"])}
  },
  "classes": {
    "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
    "allClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All classes"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "allStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All students"])},
    "bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson package"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "allLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class"])}
  },
  "lessonPackages": {
    "showPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public lesson packages"])},
    "publicBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public lesson packages"])},
    "myBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My lesson packages"])},
    "bundleLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen levels"])},
    "updateBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update lesson package"])},
    "allLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels"])},
    "removePublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't remove a public lesson package"])},
    "newPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New lesson package"])},
    "needName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson package name can't be empty!"])},
    "newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name for lesson package"])},
    "currBundleNewName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name for this lesson package"])},
    "publishBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish lesson package"])},
    "publishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to publish this lesson package? This action can not be reversed."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "psd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSD"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "tooltips": {
      "removeBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this lesson package"])},
      "publicationBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request global visibility for this lesson package"])},
      "publishBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish lesson package for global use"])},
      "alreadyPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This lesson package is already public"])},
      "cloneBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone this lesson package"])},
      "psd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels can be played by using PSD format"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels can be played by using written code"])},
      "lockBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This lesson package should be played in order"])}
    }
  },
  "teacherLevels": {
    "ownLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own levels"])},
    "deleteLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete level"])},
    "deleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this level?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "tooltips": {
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate this level"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish this level to be available for other teachers"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this level"])}
    }
  },
  "levelMaker": {
    "newLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New level"])},
    "levelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])},
    "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place block"])},
    "makeLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make new level"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks"])},
    "errors": {
      "nameNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name needed"])},
      "answerNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing answer"])},
      "startNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start block needed"])},
      "stopNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish block needed"])},
      "muchStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many start blocks used"])},
      "muchStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many finish blocks used"])},
      "pathNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Path is not compleet"])},
      "switchEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to level editor"])}
    },
    "selectableBlocks": {
      "square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierkant"])},
      "circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rondje"])},
      "heart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hart"])},
      "diamond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamand"])},
      "triangle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driehoek"])},
      "hexagon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hexagon"])}
    },
    "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
    "themes": {
      "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm"])},
      "desert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desert"])}
    },
    "seedGen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate a new seed"])},
    "goldNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieve minimum gold"])},
    "silverNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieve minumum silver"])},
    "bronzeNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieve minimum bronze"])}
  },
  "levels": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class and lesson package"])}
  },
  "game": {
    "achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieved"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "levelOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level overview"])},
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
    "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "savedSucceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "savedFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "tooltips": {
      "breakpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place points where the program needs to stop"])},
      "removeBreakpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this breakpoint"])},
      "psdSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch between PSD and code"])},
      "switchAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch between level answer and level editor"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the answer to the level"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo"])},
      "stepOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the next step"])},
      "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop running level"])},
      "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run level"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save level"])},
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See an example of the level"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the level so that it can be used in lesson packages"])},
      "addVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the variable"])},
      "addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the option"])},
      "removeOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove the option"])}
    }
  },
  "achievements": {
    "medalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My medals"])},
    "achievementHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My achievements"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New achievement(s) earned!"])},
    "noImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image"])},
    "name": {
      "golds10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 Gold medals"])},
      "golds50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 Gold medals"])},
      "golds100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 Gold medals"])},
      "superMedal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super medal"])},
      "ifStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If-statement"])},
      "whileLoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While-loop"])},
      "moved100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 Times moved"])},
      "moved500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500 Times moved"])},
      "moved1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000 Times moved"])},
      "dead10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Died 10 times"])},
      "dead50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Died 50 times"])},
      "dead100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Died 100 times"])}
    },
    "description": {
      "golds10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn 10 or more gold medals."])},
      "golds50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn 50 or more gold medals."])},
      "golds100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn 100 or more gold medals."])},
      "superMedal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn a super medal."])},
      "ifStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete a level using an if-statement."])},
      "whileLoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete a level using a while-loop."])},
      "moved100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move 100 or more times."])},
      "moved500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move 500 or more times."])},
      "moved1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move 1000 or more times."])},
      "dead10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die 10 or more times."])},
      "dead50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die 50 or more times."])},
      "dead100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die 100 or more times."])}
    }
  },
  "statistics": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "totalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total statistics"])},
    "avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "avarage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "total2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "timesOpened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["times opened"])},
    "timesOpenedCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times opened"])},
    "timeSpend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time spent"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempts"])},
    "attemptsCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of attempts"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "successfullRuns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful runs"])},
    "memoryUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["memory usage"])},
    "memoryUsageCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory usage"])},
    "solutionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See student solution"])},
    "allWorkingSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels where everyone has a working solution"])},
    "peopleWorkingSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students with a working solution"])},
    "levelsWorkingSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels with a working solution"])},
    "allWorkedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels where everyone has worked on"])},
    "peopleWorkedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students that have worked on this level"])},
    "levelsWorkedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels that have been worked on"])},
    "ideLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language used for IDE"])},
    "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working"])},
    "notWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not working"])},
    "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong: statistics could not be loaded."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "tooltips": {
      "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of memory that a student used for their current solution in comparison to the known optimal solution. Which medal you get is depended on this. Super < 0%, Gold: = 0%, Silver ≤ 15%, Bronze ≤ 30%"])},
      "successRuns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The percentage of runs with a solution that could complete the level. A higher percentage means less runs ended in failure."])}
    },
    "world": {
      "stepsTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps taken"])},
      "timesDied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times died"])}
    }
  },
  "wiki": {
    "exampleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a wiki page"])},
    "progHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About programming"])},
    "codeQuestHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About CodeQuest Online"])},
    "psd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The PSD"])},
    "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dragging blocks into the PSD"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving progress"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing code"])},
    "break": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakpoints"])},
    "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text / strings"])},
    "bool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booleans"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datatypes"])},
    "var": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
    "enum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options / Enums"])},
    "newvar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding new variables"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculating and programming"])},
    "par": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parentheses"])},
    "bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binary operators"])},
    "assi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigning values ( := )"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking values"])},
    "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching input"])},
    "condi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements and conditions"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean operators: the AND"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean operators: the OR"])},
    "exor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean operators: the EXOR"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean operators: the NOT"])},
    "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The If-function"])},
    "while": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The While-function"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing code"])},
    "pow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exponentiation ( ^ )"])},
    "mod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modular arithmetic ( % )"])},
    "inf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infinity ( ∞ )"])},
    "codequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About CodeQuest Online"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging In"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The profile page"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing a language"])},
    "progway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Way of programming"])},
    "levelsel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level selection"])},
    "levclose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level selection: locked levels"])},
    "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earning medals"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress and statistics"])},
    "achev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievements"])},
    "classWiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For teachers: Class management"])},
    "classign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For teachers: How to assign lesson packages"])},
    "packWiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For teachers: How to make lesson packages"])},
    "levMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For teachers: The Level Maker"])},
    "psdDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The PSD (Program Structure Diagram) is a scheme you can fill with programming code. The code exists of blocks and you can drag those blocks into the PSD to write a computer program that makes the character move the direction you want it to."])},
    "psdDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With CodeQuest Online you will learn how to write a PSD program by yourself."])},
    "dragDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can drag instructions into the PSD and you can choose where you want to place those blocks. You can do this by setting your mouse above the instruction and holding the left button. After that you move your mouse with the block to the right place in the PSD and release."])},
    "saveDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above the PSD you can see a green button with a little SD card on it. You can push this button if you want to save your current progress on your PSD program. This is convenient for when you want to continue later with the level but you don't want to lose the progress you already made."])},
    "testDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you think you have programmed the solution, you can test your code. Push the green arrow button above the PSD to run the program. You will see that your character will start to move just like you programmed it to. With the orange arrows you can go one step ahead or back in the process of the running program."])},
    "breakDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakpoints are points in your code where you want your program to automatically pause during execution. You can add these breakpoints yourself by pushing the red pause button and to push on the block in the PSD where you want a breakpoint. This is a great tool if you have a mistake in your program but you're not sure what's going wrong. When the program pauses there, you have all the time to check what the values are at that point and have a better perspective to improve your code."])},
    "breakDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the image above you can see that we added breakpoints on two instructions, as you recognize them by the red squares. You can remove a breakpoint by clicking the pause button and then the breakpoint again. You can also use breakpoints when writing code."])},
    "stringDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A piece of text consists of a row of letters and other reading symbols. Programmers use the term 'string' for this. The start and the and of a string are declared by quotation marks, for example 'robot'."])},
    "boolDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A boolean is a value with only two possibilities: de value is 'true' or 'false'. This is an easy value for computers to work with. Programmers use a shorter version to indicate the difference between true and false. A value of '1' is 'true' and a value of '0' means 'false'. "])},
    "dataDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data saved on a computer can have multiple different meanings. Take a number for example, that is a value you can calculate with. But you also have different kinds of datatypes like the string and the boolean you learned about above here. It is important during programming to not mix different datatypes. For example, you can not add up the values 'computer' + 6', because you cannot simply add up a number and a string."])},
    "varDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A variable is a small piece of your program that carries a value. Mostly this value will be changing during the execution of your program. This makes the value variable, that's also how the variable got its name. Variables always have a certain name so you can easilly call it and see what value the variable carries. For example, a variable called 'ageOfRobbie' will hold a number that stands for the age of the character named Robbie."])},
    "varDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, only having variables is a little boring. As a programmer you want to change and alter your variable values, because that means there is actually something happening in your program. You can use variables to do calculations, to check values and a lot more, the sky is the limit."])},
    "enumDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An enum is a special kind of variable. With this variable you only have a limited number of possible values, you can declare which those values are. A good example of an enum is the current day of the week: The variable will be named something like dayOfTheWeek and the only possible values will be Monday, Tuesday, Wednesday, Thursday, Friday, Saturday and Sunday. Under here you see another good example of an enum that's already added in CodeQuest, the move. With this enum you can set the direction of the character upwards, downwards, to the left or to the right."])},
    "newvarDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also add new variables when solving a level. First you need to choose the correct datatype. Does the variable need to be a number, string, boolean or enum? After that you choose an appropriate name and you push the green button to create your variable. Your variable will instantly be added to the instruction list and you can also immediately use it in the PSD."])},
    "calcDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation in computer programs works just like regular calculation. You take two numbers and you add them up ( + ) , subtract from each other ( - ), multiply with them ( * ) or divide on by the other ( / ). You can only execute these calculations with numbers or variables that hold a number."])},
    "parDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have probably already learned with calculus that you always have a certain order in a calculation. Here you learned that you always need to start with the numbers inside the parentheses. We also apply this rule during programming. If you want to calculate something correctly before everything else, you need to put it in parentheses."])},
    "binDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A binary operator is an instruction that uses 2 values (also called inputs). Take the plus ( + ), that takes two numbers and adds them up. During programming you will use a lot of binary operators."])},
    "assiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigning means 'giving a variable a certain value'. For this you use the special symbol := . On the left side you put the variable you want to change. On the right side you put the value or another variable that holds the value you want to assign. Here a couple of examples: ' ageOfRobbie := 6 ' means that the variable ageOfRobbie will get the value 6, so Robbie is 6 years old. ' ageOfRobbie := ageOfDanny' means that ageOfRobbie will copy the value of ageOfDanny, meaning Robbie and Danny will have the same age."])},
    "assiDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It can also happen that you want to combine multiple values in one assignment. This means you have to use binary operators on the right side. For example: we want to say that Robbie is 10 years older then Danny. If we want to set the age of Robbie, we do it like this: ' ageOfRobbie := ageOfDanny + 10 '. All of the binary operators can be used in an assignment, as long as the correct datatype is used."])},
    "checkDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking a value means that you will compare variables or values with each other. You have multiple options for this. First you have the '=', which checks if two values are exactly the same, if this is true it will return a 1, otherwise it will return a 0. For example, '6 = 6' is true so it returns a 1 and '6 = 9' is false so it returns a 0."])},
    "checkDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also check whether a value is bigger or smaller then another value with the > and < arrows, with these you always check if the first value is bigger or smaller then the second value. Note that the number the arrow points to needs to be the smaller number to return a true. For example, the comparison 9 > 6 is true so it returns a 1, but 9 < 6 is false, it returns 0. 9 < 9 is also false, because a number is never bigger or smaller than itself."])},
    "checkDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You also have the symbols ≥ en ≤, these are actually < or > and the = combined, so they check if value 1 bigger / smaller or the same as value 2. This means that 9 ≥ 6 en 9 ≥ 9 both are true, but 9 ≥ 10 is false."])},
    "checkDes4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also use the opposite of a =, so you check if two values are not the same, with the symbol ≠. In this case the comparison '6 ≠ 9' is actually true so it returns a 1."])},
    "checkDes5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are all operators that will return a true or false when the comparison is correct or not, meaning it will always return a boolean. With the multiple bigger / smaller then symbols you can only compare numbers. With the = and ≠ you can also compare strings or booleans."])},
    "switchDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you added an assignment to the PSD , you can choose whether you want to write down a value yourself or drag in a variable or operator. You can switch between these two options by clicking the small orange button."])},
    "condiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With some instructions you want to give a condition. A condition determines whether a certain instruction will be executed or not. The condition always consists of a value check. For example, in the following image you see a condition that says the character must be standing on a heart figure."])},
    "andDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The AND operator is a binary operator that compares two boolean values. The result of this comparison will be a bool. This value will only be a '1' when both of the input values are true. Otherwise the result will always be '0'. You can only use boolean values with this operator."])},
    "orDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OR operator is a binary operator that compares two boolean values. The result of this comparison will be a bool. The comparison will return a '1' if one or both of the compared values are true. If both values arefalse, the result will also be '0'. You can only use boolean values with this operator."])},
    "exorDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The EXOR operator is a binary operator that compares two boolean values. The result of this comparison will be a bool. The resulting value will be 1 when the input values differ (so one of them is true and one is false). If the input values have the same value (both are true or false) the result will be '0'. You can only use boolean values with this operator."])},
    "notDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NOT is an unary oprator, meaning you only use one input value. The NOT gets one boolean value and flips it. A true becomes a false and a false becomes a true. You can only use boolean values with this operator. If you're writing code, you can add this by using an exclamation mark before the variable, like '!red'"])},
    "ifDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the IF operator you always have two possible paths. To determine which path you follow and thus which code you will execute, you check the value with the condition. If the condition returns true, you will execute the code on the 'true' side once. If it returns false, it will do the instructions on the 'false' side. The condition has to be an operator returning a boolean value."])},
    "ifDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the example above we check if we are standing on a heart. If we stand on a heart, the result is a true and we will follow the true branch, meaning we will move to the left (see the code marked in yellow). When we are not standing on a heart, we follow the 'false' branch (code in light blue) and we move up."])},
    "whileDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The While instruction is a loop, so it will often repeat multiple times. The condition will be checked before every round, and as long as the value is true, the code in the while loop will be executed. When the condition returns false, it will stop the loop and continue with the rest of the PSD. The condition has to be an operator returning a boolean value."])},
    "whileDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the example above we check if we are standing on a heart. As long as we are standing on a heart (meaning the condition is true), we will repeat executing the code in the while loop, meaning we continue moving left (marked with yellow). When we arrive on another symbol, it will stop the while loop and go to the code under the loop (in light blue). Then it will move up."])},
    "writeDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besides using a PSD you can also write code. That may seem complicated at first, but it's not that bad. You basically use the same words and characters that you will encounter in the PSD. But you will also see some differences. We will list the most important rules here."])},
    "writeDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. An instruction always ends with a semicolon, so a ;. This tells the computer that it can go to the next instruction and that the instructions are separated from each other."])},
    "writeDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. For large statements with multiple assignments, use braces, which are these graceful parentheses. You use this, for example, for the while and the if functions. Note that with the if function you put the parentheses first before the true branch and below that the parentheses for the false branch, you also have to write else before the parentheses."])},
    "writeDes4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Conditions are often in parentheses such as ( tile == red ) after the while and the if."])},
    "powDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exponentiation is a binary operator and a special kind of multiplication. The second number indicates how many times you want to multiply the first number with itself. For example: the Exponentiation ' 2 ^ 4 ' means that you multiply a 2 with itself 4 times, this is the same as the multiplication ' 2 x 2 x 2 x 2 '. You can only exponentiate with numbers."])},
    "modDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modular arithmetic is a special kind of division. With modular arithmetic you want to know how much will be left when you divide a number in multiple equally sized pieces. To better imagine this, pretend you're throwing a pizza party and you want to give every guest 4 slices. Now you only have 14 slices of pizza, which you cannot divide by 4. We don't care about how many guests will get pizza, guests get 4 slices or they get nothing. This means that you can give pizza to 3 guests ( because 3 x 4 slices = 12 slices) and you have 2 slices remaining. We don't want to know how many times we can give people pizza, we only want to know how many pizza will be left over. In short you can write that down as: 14 % 4 = 2. Interesting is that the following will give the same result: 6 % 4 = 2, because 6 is something times 4 plus 2, just like 14 is. You can only do modular arithmetic with numbers."])},
    "modDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another example of using modular arithmetic is when using a digital clock. When the clock says '21:15' you say it is a quarter past 9. Although the clock says 21 hours, when you read the time you actually calculate 21 % 12 = 9. The following video will give more information about this."])},
    "infDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infinity is a special symbol that does exactly what the name says: it is infinite. You can use this to keep a program continue without ever stopping."])},
    "codequestDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CodeQuest Online is an online program to learn the basic concepts of programming in a fun and interactive way. Your goal is to write multiple instructions to move your character through a maze and by that you learn how to think about writing computer programs. CodeQuest Online is a product developed by Team Quest2D and it originates from a collaboration between the Stichting Praktijkleren and Utrecht University."])},
    "loginDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the login page you can log in to CodeQuest Online to get to your personal user page, where you can solve all the puzzles your teacher picked out for you. To log in you use your personal username and password provided bij Stichting Praktijkleren. "])},
    "profileDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the profile page you can see your personal data, you can see the classes you enrolled in and you can change your avatar."])},
    "avatarDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars are profile pictures you can choose for your profile. This makes it easy to recognize that you are logged in, because then you can see your avatar in the right corner. You can choose your own avatar that fits the best with you. Don't forget to save any changes by pressing the save button."])},
    "settingDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page you can change the settings of CodeQuest Online to your own preference. You can choose for yourself if you want to turn the sound effects on or off and whether or not you want to be visible on the leaderboard. On the right side you can set your preferred language. Don't forget to press 'save' when you changed something."])},
    "langDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the settings page you can choose if you want to play CodeQuest Online in Dutch or English. You can choose here whether you want the entire page in English or Dutch, or if you only want to learn how to program with Dutch or English terms."])},
    "progwayDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In some levels, no particular way of solving has been chosen, this can be done via the PSD as well as by writing code. With this setting you can choose what you want to practice more with, in other words, do you prefer to solve levels with the PSD or with code for now."])},
    "levelselDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page you can select a level you want to play. First you select a level bundle the teacher selected for you. After that you can see which levels are available to play. Click on a level to open it and start playing it."])},
    "levcloseDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you see a big lock on the level, it means you first have to solve the other levels before you can unlock this level."])},
    "medalsDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the corner of the level you can see what medal you earned for that level. You will always see the best medal you earned for that level. Keep your code / PSD as short as possible to earn a higher medal! Can you collect all the gold medals?"])},
    "statsDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the progress page you can see your progress in CodeQuest Online. Here you can see how many levels you have completed, how many medals you've earned and you can check your unique achievements on the right."])},
    "achevDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievements show that you achieved certain milestones in CodeQuest Online. Think about things like collecting 50 golden medals. Can you collect all the achievements?"])},
    "classWikiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In your teacher environment you can view the results of all your students per level. Select a class on the left that you want to view. From here you can choose to view the performance of the entire class on a certain level or to look at the performance of a specific student. Choose a bundle that the students have been working on and view the achievements per level or across the entire bundle."])},
    "classWikiDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, the statistics opens first. This shows, for example, how many times a student (or all students) have opened and tried levels and how much time they spent on them. You also have a button here to view a student's actual solution. You can also switch to the other tab and you will see things like percentages of memory usage and the number of successful attempts."])},
    "classignDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a teacher, you can give your classes bundles that your students can then work with. Left click on the class where you want to change the bundles. Now you see in the middle a list of all bundles that have already been assigned to the class, nothing can be here in the beginning. On the right you see all available bundles. Click on a green plus sign to add that bundle to your class. Click on the red minus button to remove a bundle from the class."])},
    "classignDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you're done selecting the bundles for your class, hit the green 'update classroom' button to save your changes. Press the red reset button to revert everything to the last moment you saved."])},
    "packWikiDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a teacher, you can create lesson packages for your students yourself. This can be done via the 'Make lesson package' tab under the teachers menu. When you create a new lesson package, first enter a name for that package. That could be the subject of the package, for example. Press 'New lesson package' to create that package. Then click on that package to be able to drag levels into it."])},
    "packWikiDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding levels is very easy. On the right you see all levels, there you can click on the plus button to add the level. If you want to remove a level, click on the red minus button."])},
    "packWikiDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is also important to indicate whether students have to solve the levels via a PSD or via written code. In addition, you can also indicate whether the levels must be solved in sequence (that means that a level is locked for the student until he has solved all previous levels) or whether he can access all levels immediately. The second is set as default."])},
    "levMakerDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In CodeQuest Online you as a teacher have the option to create your own levels. Start a new level by left clicking on the large area with a plus on it. The level maker will open and first ask you to name it. Choose a name and press 'Create new level'."])},
    "levMakerDes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now the level maker opens and here you have several actions that you can do. Let's start placing blocks. In the middle you have a plane with all available blocks. Select a certain block and then click a box in the grid to place that square there. That block remains selected until you click on another color block. Start and end are the start and end points of the level, with these blocks a menu appears in which you can indicate what kind of figure should be drawn on these surfaces."])},
    "levMakerDes3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the grid you have a number of other options that you see at the top right. You can zoom in and out with the + and - buttons. This can also be done with the mouse wheel. You also have the hand, if you click that button you can drag the grid around."])},
    "levMakerDes4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the top you also have a number of options. Use the orange arrows to undo or redo an action. Press the green save button to save your work. At the far right you can press upload when you are finished creating the level and if you want to make the level actually playable for your students. Don't forget that you still have to add the level to a bundle."])}
  },
  "profile": {
    "userInfoBlockHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "avatarBlockHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars"])},
    "avatarButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "classroomBlockHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classrooms"])},
    "permission": {
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])}
    }
  },
  "settings": {
    "game": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
      "soundEffects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soundeffects"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])}
    },
    "language": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website language"])},
      "programming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming language"])},
      "programmingStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Way of programming"])},
      "schematically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schematic"])},
      "typed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typing"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])}
    },
    "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "classroomPackages": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "myClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Classrooms"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update classroom"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated classroom succesfully!"])},
    "availableBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available lesson packages"])},
    "selectClassroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a classroom"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
  }
}