import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import createPersistedState from 'vuex-persistedstate';
import * as types from "@/utils/Types";
import jwt_decode from "jwt-decode";
interface RootState {
  authTokens: types.authTokens | undefined;
  loginToken: string | undefined;
  stats: types.stats;
  bundle: number | null;
  levels: types.level[] | null;
  classroomBundles: types.bundle[] | null;
  levelState: types.levelState[] | null;
  code: string[] | null;
  userInfo: types.userInfo | undefined;
  preferences: types.userPrefs;
  worldData: types.worldData;
  levelMakerInfo: types.levelMakerInfo;
  levelInfo: types.levelInfo;
  levelSettings: types.levelSettings;
  levelSave: types.SaveObject | undefined;
  theme: string;
  isPreview: boolean;
}

const key: InjectionKey<Store<RootState>> = Symbol();

//Store
const store = createStore<RootState>({
  state: {
    authTokens: undefined,
    loginToken: undefined,
    stats: {
      deaths: 0,
      bronzeMedals: 0,
      silverMedals: 0,
      goldMedals: 0,
      achievements: {},
    },
    userInfo: undefined,
    bundle: null,
    levels: null,
    levelState: null,
    code: null,
    preferences: {
      ideLang: "nl",
      siteLang: "nl",
      programmingStyle: "schematically",
      soundEnabled: true,
      musicEnabled: false,
    },
    worldData: {
      constants: [],
      functions: [],
      variables: [],
    },
    levelMakerInfo: {
      levelId: -1,
      layout: null,
      answerBlocks: new Set(),
      extraBlocks: new Set(),
      remainingBlocks: new Set(),
      constraints: [
        { name: "gold", langid: "goldNeeded", value: false },
        { name: "silver", langid: "silverNeeded", value: false },
        { name: "bronze", langid: "bronzeNeeded", value: false },
      ],
      name: "",
      html: "",
      gridTiles: [],
      newLevel: true,
      seed: 0,
      theme: "farm",
    },
    levelInfo: {
      levelId: 0,
      userId: "undefined",
      name: "undefined",
      levelData: {
        levelInstructions: {
          needed: [],
          extra: [],
        },
        size: {
          width: 15,
          height: 17,
        },
        seed: 0,
        start: [],
        end: [],
        path: [],
        interactables: [],
        obstacles: [],
        entities: [],
        theme: "farm",
        nrOfMedals: "three",
      },
      preview: "",
      solution: "undefined",
      timestamp: "undefined",
      published: false,
      world: "undefined",
      seed: 0,
    },
    classroomBundles: [],
    levelSettings: {
      levelId: 0,
      allowPsd: false,
      allowWritten: false,
    },
    levelSave: undefined,
    theme: "farm",
    isPreview: false,
  },
  //Setters
  mutations: {
    setAuthTokens(state, authTokens: types.authTokens) {
      state.authTokens = authTokens;
      // Set the authtoken cookies
      if (authTokens) {
        localStorage.setItem("authToken", authTokens.authToken);
        localStorage.setItem(
          "authTokenExp",
          jwt_decode<types.JWTToken>(authTokens.authToken).exp.toString()
        );

        localStorage.setItem("refreshToken", authTokens.refreshToken);
        localStorage.setItem(
          "refreshTokenExp",
          jwt_decode<types.JWTToken>(authTokens.refreshToken).exp.toString()
        );
      } else {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authTokenExp");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("refreshTokenExp");
      }
    },
    setStats(state, stats: types.stats) {
      state.stats = stats;
    },
    setUserInfo(state, userInfo: types.userInfo) {
      state.preferences = userInfo.preferences;
      state.userInfo = userInfo;
      if (userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem(
          "preferences",
          JSON.stringify(userInfo.preferences)
        );
      } else {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("preferences");
      }
    },
    setAvatarId(state, avatarId: number) {
      if (state.userInfo) {
        state.userInfo.avatarId = avatarId;
      }
    },
    setBundle(state, bundle: number) {
      state.bundle = bundle;
    },
    clearBundle(state) {
      state.bundle = null;
    },
    setLevels(state, levels: types.level[]) {
      state.levels = levels;
    },
    clearLevels(state) {
      state.levels = new Array<types.level>();
    },
    setGameState(state, level: types.levelState[]) {
      state.levelState = level;
    },
    clearGameState(state) {
      state.levelState = new Array<types.levelState>();
    },
    setCode(state, code: string[]) {
      state.code = code;
    },
    setPreferences(state, prefs: types.userPrefs) {
      state.preferences = prefs;
    },
    setIDELanguage(state, lang: string) {
      state.preferences.ideLang = lang;
    },
    setWebsiteLanguage(state, lang: string) {
      state.preferences.siteLang = lang;
    },
    setProgrammingStyle(state, style: string) {
      state.preferences.programmingStyle = style;
    },
    setSoundSettings(state, enabled: boolean) {
      state.preferences.soundEnabled = enabled;
    },
    setMusicSettings(state, enabled: boolean) {
      state.preferences.musicEnabled = enabled;
    },
    setWorldData(state, data: types.worldData) {
      state.worldData = data;
    },
    setLevelInfo(state, info: types.levelInfo) {
      state.levelInfo = info;
    },
    setLevelPreview(state, preview: string) {
      state.levelInfo.preview = preview;
    },
    clearLevelInfo(state) {
      state.levelInfo = {} as types.levelInfo;
    },
    setLevelId(state, id: number) {
      state.levelInfo.levelId = id;
    },
    setLevelSave(state, save) {
      console.log('SAVE', save);
      state.levelSave = save;
    },
    setClassroomBundles(state, info: types.bundle[]) {
      state.classroomBundles = info;
    },
    clearClassroomBundles(state) {
      state.classroomBundles = {} as types.bundle[];
    },
    setLevelSettings(state, info: types.bundleLevelSettings) {
      state.levelSettings = info;
    },
    clearLevelSettings(state) {
      state.levelSettings = {} as types.bundleLevelSettings;
    },
    setTheme(state, theme: string) {
      state.theme = theme;
    },
    setLevelMaker(state, level: types.levelInfo) {
      state.levelMakerInfo.name = level.name;
      state.levelMakerInfo.levelId = level.levelId;
      state.levelMakerInfo.layout = level.levelData;
      state.levelMakerInfo.seed = level.seed;
      if (level.levelData.levelInstructions.needed) {
        state.levelMakerInfo.answerBlocks = new Set<string>(
          level.levelData.levelInstructions.needed.map((x) => x.name)
        );
      }
    },
    setLevelMakerId(state, id: number) {
      state.levelMakerInfo.levelId = id;
    },
    setLevelMakerAnswerBlocks(state, data: Set<string>) {
      state.levelMakerInfo.answerBlocks = data;
    },
    setLevelMakerExtraBlocks(state, data: Set<string>) {
      state.levelMakerInfo.extraBlocks = data;
    },
    setLevelMakerRemainingBlocks: (state, value: Set<string>) => {
      state.levelMakerInfo.remainingBlocks = value;
    },
    setLevelMakerAnswerHTML(state, data: string) {
      state.levelMakerInfo.html = data;
    },
    setLevelMakerConstraints(
      state,
      data: { name: string; langid: string; value: boolean }[]
    ) {
      state.levelMakerInfo.constraints = data;
    },
    setLevelMakerName(state, data: string) {
      state.levelMakerInfo.name = data;
    },
    setLevelMakerSeed(state, data: number) {
      state.levelMakerInfo.seed = data;
    },
    setLevelMakerTheme(state, data: string) {
      state.levelMakerInfo.theme = data;
    },
    setLevelMakerGrid(state, grid: types.gridCoordinates[]) {
      state.levelMakerInfo.gridTiles = grid;
    },
    setLevelMakerNewLevel(state, bool: boolean) {
      state.levelMakerInfo.newLevel = bool;
    },
    resetLevelMakerData(state) {
      state.levelMakerInfo.levelId = -1;
      state.levelMakerInfo.layout = null;
      state.levelMakerInfo.answerBlocks = new Set();
      state.levelMakerInfo.extraBlocks = new Set();
      (state.levelMakerInfo.remainingBlocks = new Set()),
        (state.levelMakerInfo.constraints = [
          { name: "gold", langid: "goldNeeded", value: false },
          { name: "silver", langid: "silverNeeded", value: false },
          { name: "bronze", langid: "bronzeNeeded", value: false },
        ]);
      state.levelMakerInfo.name = "";
      state.levelMakerInfo.html = "";
      state.levelMakerInfo.gridTiles = [];
      state.levelMakerInfo.newLevel = true;
    },
    turnPreviewOff(state) {
      state.isPreview = false;
    },
    turnPreviewOn(state) {
      state.isPreview = true;
    },
  },
  //Getters
  getters: {
    getAuthTokens: (state) => {
      return state.authTokens;
    },
    getStats: (state) => {
      return state.stats;
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getBundle: (state) => {
      return state.bundle;
    },
    getLevels: (state) => {
      return state.levels;
    },
    getLevelState: (state) => {
      return state.levelState;
    },
    getLevelSave: (state) => {
      return state.levelSave;
    },
    getCode: (state) => {
      return state.code;
    },
    getPreferences: (state) => {
      return state.preferences;
    },
    getIDELanguage: (state) => {
      return state.preferences.ideLang;
    },
    getWebsiteLanguage: (state) => {
      return state.preferences.siteLang;
    },
    getProgrammingStyle: (state) => {
      return state.preferences.programmingStyle;
    },
    getSoundSettings: (state) => {
      return state.preferences.soundEnabled;
    },
    getMusicSettings: (state) => {
      return state.preferences.musicEnabled;
    },
    getLevelMakerlayout: (state) => {
      return state.levelMakerInfo.layout;
    },
    getLevelMakerSeed: (state) => {
      return state.levelMakerInfo.seed;
    },
    getLevelMakerTheme: (state) => {
      return state.levelMakerInfo.theme;
    },
    getLevelMakerId: (state) => {
      return state.levelMakerInfo.levelId;
    },
    getLevelMakerAnswerBlocks: (state) => {
      return state.levelMakerInfo.answerBlocks;
    },
    getLevelMakerExtraBlocks: (state) => {
      return state.levelMakerInfo.extraBlocks;
    },
    getLevelMakerRemainingBlocks: (state) => {
      return state.levelMakerInfo.remainingBlocks;
    },
    getLevelMakerAnswerHTML: (state) => {
      return state.levelMakerInfo.html;
    },
    getLevelMakerConstraints: (state) => {
      return state.levelMakerInfo.constraints;
    },
    getLevelMakerName: (state) => {
      return state.levelMakerInfo.name;
    },
    getLevelMakerGrid: (state) => {
      return state.levelMakerInfo.gridTiles;
    },
    getLevelMakerNewLevel(state) {
      return state.levelMakerInfo.newLevel;
    },
    getWorldData: (state) => {
      return state.worldData;
    },
    getLevelInfo: (state) => {
      return state.levelInfo;
    },
    getLevelSettings: (state) => {
      return state.levelSettings;
    },
    getTheme: (state) => {
      return state.theme;
    },
    getIsPreview: (state) => {
      return state.isPreview;
    },
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
  })],
});

// Can't be tested yet, because it can only be used in components
// otherwise there is no store to return
function useStore(): Store<RootState> {
  return baseUseStore(key);
}

export { key, store, useStore, RootState };
