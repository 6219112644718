
import { Options, Vue } from "vue-class-component";
import { currentAvatar } from "@/utils/Avatars";
import { getUserInfo } from "@/utils/Functions";

@Options({
  data() {
    return {
      teacherDropdown: "",
      profileDropdown: "",
    };
  },
  computed: {
    /**
     * Check if user has authentication tokens
     * @returns Bool yes or no tokens
     */
    hasAuthTokens() {
      return this.$store.getters.getAuthTokens !== undefined;
    },
  },
  methods: {
    //Log user out
    logOut() {
      this.$store.commit("setAuthTokens", undefined);
      this.$store.commit("setUserInfo", undefined);
    },
    currentAvatar,
    getUserInfo,
  },
})
export default class Navbar extends Vue {}
